import * as React from "react";
import TagList from "./tagList";
import { navigate } from "gatsby-link";

export default ({ title, src, description, tags }) => (
  <div to={src} className="postOverview" onClick={() => navigate(src)}>
    <div style={{ paddingLeft: 10 }}>
      <h3>{title}</h3>
      <p>{description}</p>
      <p>
        <TagList tags={tags || []} />
      </p>
    </div>
    <hr />
  </div>
);
