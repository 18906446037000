import * as React from "react";

export default ({ tags, style }) => {
  return (
    <small style={style}>
      {(tags || []).map((tag) => (
        <span
          style={{
            padding: 4,
            marginRight: 12,
            backgroundColor: "#0074D9",
            borderRadius: 3,
            color: "white",
          }}
          key={tag}
        >
          {tag}
        </span>
      ))}
    </small>
  );
};
