import React from "react";
import { graphql } from "gatsby";

import PostOverview from "../components/postOverview";
import Layout from "../components/Layout";

export default function Index(params) {
  const { data } = params;
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <Layout>
      <div>
        <h1>Code Blog</h1>

        <hr />

        {posts.map(({ node: post }, index) => (
          <PostOverview
            key={index}
            src={post.frontmatter.path}
            tags={post.tags || []}
            title={post.frontmatter.title}
            description={post.frontmatter.summary}
          />
        ))}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            summary
          }
        }
      }
    }
  }
`;
